import React from "react";

// import image from "../../images/adcash.png";
//import TableRows from '../components/TableRows';

const Header = () => {
  return (
    <thead>
      <tr>
        <th>Sat</th>
        <th>Sun</th>
        <th>Mon</th>
        <th>Tue</th>
        <th>Wed</th>
        <th>Thur</th>
        <th>Fri</th>
      </tr>
    </thead>
  );
};

export default Header;
